import {
  FC,
  FocusEventHandler,
  FocusEvent,
  KeyboardEvent,
  ChangeEventHandler,
  InputHTMLAttributes,
  ChangeEvent,
} from 'react';
//types
import {FieldBaseProps} from 'core/types/field';
//components
import FieldWrapper from 'shared/inputs/FieldWrapper';
//styles
import InputBase from './InputBase';

type InputProps = FieldBaseProps<
  Pick<InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder' | 'min' | 'max' | 'onKeyPress'> & {
    isPencilHidden?: boolean;
    onFocus?: () => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    autocomplete?: string;
  }
>;

const Input: FC<InputProps> = ({type, placeholder, min, max, isPencilHidden, ...fieldBaseProps}) => {
  const {field, disabled, dir, onFocus, onKeyPress, onChange, onBlur, autocomplete, form} = fieldBaseProps;

  const {setFieldTouched, setFieldValue, touched} = form;

  const onFocusHandler = () => {
    if (onFocus) onFocus();
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);
    setFieldTouched(field.name);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    else if (type === 'number') {
      const value = e.target.value;
      const regex = /^[1-9][0-9]*$/;

      if (value === '' || regex.test(value)) {
        setFieldValue(field.name, value).then();
      }
    } else {
      field.onChange(e);
    }
  };

  const onKeyDownHandle = (e: KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number') {
      if (['.', 'e', '+', '-'].includes(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <InputBase
        {...field}
        id={field.name}
        className={fieldBaseProps.parentClassName}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onKeyPress={onKeyPress}
        type={type}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandle}
        placeholder={placeholder}
        disabled={disabled}
        min={min}
        max={max}
        isPencilHidden={isPencilHidden}
        dir={dir}
        autoComplete={autocomplete}
      />
    </FieldWrapper>
  );
};
export default Input;
