import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
// Redux
import {useSelector} from 'react-redux';
import {Store} from 'redux/root';
// API
import {IInviteUserRequest} from '@joc/api-gateway';
// Urls
import {urls} from 'core/appUrls';
// Functions
import {overTariffCount} from 'core/functions/overTariffCount';
import {invitePopupSubmitClickHandler} from 'core/functions/submitClickHandler';
// Component
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Loader from 'shared/components/Loader';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import InvitedVolunteersTable from '../InvitedVolunteersTable';
import InputImportList from 'shared/components/FormInputs/InputImportList';
import ChangeDefaultPasswordPopup from './setPasswordPopup';
// Hooks
import {useTranslation} from 'react-i18next';
// Styles
import styles from '../InvitePopup.module.scss';
import {selectorPayGateStatus} from '../../../../../../redux/organization-service/selector';

type AddByListProps = {
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setCustomError: Dispatch<SetStateAction<string | undefined>>;
  confirmationSubmitClickHandler: () => void;
  setIsEmpty: Dispatch<SetStateAction<boolean>>;
  setIsShowConfirmationChange: Dispatch<SetStateAction<boolean>>;
  isShowConfirmationChange: boolean;
  customError?: string;
};

export const AddByList: FC<AddByListProps> = ({
  setIsSuccess,
  setCustomError,
  confirmationSubmitClickHandler,
  setIsShowConfirmationChange,
  isShowConfirmationChange,
  setIsEmpty,
  customError,
}) => {
  const [addedVolunteers, setInvitedVolunteers] = useState<Array<IInviteUserRequest>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowConfirmClear, setIsShowConfirmClear] = useState(false);
  const [isNext, setIsNext] = useState(false);

  // Temporary solution with disabling pay gate
  const volunteersCountByTariff = useSelector((store: Store) => store.dashboardRedux.main.volunteersCountByTariff);
  const payGateStatus = useSelector(selectorPayGateStatus);
  const volunteersTotal = useSelector((store: Store) => store.dashboardRedux.main.volunteersTotal);
  const orgId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.id);
  const schoolId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.schoolId);

  useEffect(() => setIsEmpty(!addedVolunteers?.length), [addedVolunteers]);

  const {t} = useTranslation(['inviteColleagues', 'buttons']);

  const handleSetPasswordForStudents = (password: string) => {
    setIsNext(false);

    if (addedVolunteers?.length) {
      invitePopupSubmitClickHandler(addedVolunteers, orgId, setIsLoading, setIsSuccess, setCustomError, true, password);
    }
  };

  return (
    <>
      <div className={styles.list}>
        <div className={styles.list__wrapper}>
          <div className={styles.list__nav}>
            <InputImportList
              setStateValue={setInvitedVolunteers}
              listLength={addedVolunteers?.length}
              invitedUserType={!!schoolId ? 'student' : 'volunteer'}
              addByList
              exampleFilePath={`${process.env.PUBLIC_URL}/assets/documents/addStudentsTemplate.xlsx`}
            />
            {!!addedVolunteers?.length && (
              <ButtonDefault
                classList={['secondary']}
                title={t('buttons:button.clearAll')}
                clickHandler={() => setIsShowConfirmClear(true)}
              />
            )}
          </div>
          {!!addedVolunteers?.length &&
            !!overTariffCount(volunteersTotal, volunteersCountByTariff, payGateStatus, addedVolunteers.length) && (
              <>
                <div className={styles.backRed}>
                  {t('inviteColleagues:messageEndTariff')}
                  <br />
                  {t('inviteColleagues:messagePlease')}
                  <Link to={urls.tariffPlans}>{t('inviteColleagues:tariffPlans')}</Link>
                </div>
              </>
            )}
          {!!addedVolunteers?.length && (
            <InvitedVolunteersTable
              overTariffStartIndex={overTariffCount(
                volunteersTotal,
                volunteersCountByTariff,
                payGateStatus,
                addedVolunteers.length
              )}
              volunteers={addedVolunteers}
            />
          )}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          !!addedVolunteers?.length && (
            <ButtonDefault
              disabled={
                !!overTariffCount(volunteersTotal, volunteersCountByTariff, payGateStatus, addedVolunteers.length) ||
                !!customError
              }
              classList={['primary', 'lg']}
              title={t('buttons:button.next')}
              clickHandler={() => setIsNext(true)}
            />
          )
        )}
      </div>
      {isNext && (
        <ChangeDefaultPasswordPopup
          onSetPassword={handleSetPasswordForStudents}
          onClose={() => setIsNext(false)}
          inputType="text"
        />
      )}
      {isShowConfirmClear && (
        <PopupConfirmation
          confirmClickHandler={() => {
            setInvitedVolunteers([]);
            setIsShowConfirmClear(false);
          }}
          cancelClickHandler={() => setIsShowConfirmClear(false)}
          setIsShowPopup={setIsShowConfirmClear}
        />
      )}
      {isShowConfirmationChange && (
        <PopupConfirmation
          confirmClickHandler={() => {
            confirmationSubmitClickHandler();
            setIsShowConfirmationChange(false);
            setInvitedVolunteers([]);
          }}
          cancelClickHandler={() => setIsShowConfirmationChange(false)}
          setIsShowPopup={setIsShowConfirmationChange}
        />
      )}
    </>
  );
};
